<template>
  <div id="app">
    <CodeInput :loading="false" class="input" v-on:change="onChange" v-on:complete="onComplete" />
  </div>
</template>
 
<script>
import CodeInput from "vue-verification-code-input";
 
export default {
  name: "RandomTest",
  components: {
    CodeInput
  },
  methods: {
    onChange(v) {
      console.log("onChange ", v);
    },
    onComplete(v) {
      console.log("onComplete ", v);
    }
  },
  mounted(){
    if(this.$store.state.user == null || this.$store.state.user == undefined){
      this.$router.push({path: '/sign-in'});
    }
  }
};
</script>